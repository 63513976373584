<template>
  <v-app v-if="settingsData">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ companyName }}
            </v-list-item-title>
            <v-list-item-subtitle
              color="0027ff
"
            >
              Admin Panel
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/admin/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/admin/services">
          <v-list-item-action>
            <v-icon>mdi-chart-pie</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Servicios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="" no-action>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-account-group-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Clientes</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/admin/clients">
            <v-list-item-content>
              <v-list-item-title>Ver Clientes</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link to="/admin/clients-services">
            <!-- v-if="isAdmin()" -->
            <v-list-item-content>
              <v-list-item-title>Servicios</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-laptop</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link to="/admin/budgets">
            <v-list-item-content>
              <v-list-item-title>Presupuestos</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-file-outline</v-icon>
            </v-list-item-action>
          </v-list-item>

          <!--           <v-list-item link to="/admin/emails">
            <v-list-item-content>
              <v-list-item-title>Enviar Factura</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>
          </v-list-item> -->

          <v-list-item link to="/admin/expenses">
            <v-list-item-content>
              <v-list-item-title>Gastos</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-trending-down</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>

        <v-list-item link to="/admin/users">
          <v-list-item-action>
            <v-icon>mdi-card-account-details-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="" no-action>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-briefcase-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Portafolio</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/admin/portfolios/list">
            <v-list-item-content>
              <v-list-item-title>Ver Portafolios</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-briefcase-outline</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link to="/admin/portfolios/categories">
            <v-list-item-content>
              <v-list-item-title>Categorías</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/portfolios/slider">
            <v-list-item-content>
              <v-list-item-title>Slider Portfolio</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-image-multiple</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <!--         <v-list-item link to="/admin/configuracion/informacion-nosotros">
          <v-list-item-action>
            <v-icon>mdi-cash-usd-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Facturación</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
 -->

        <!--         <v-list-group prepend-icon="" no-action>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-post-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Publicaciones</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/admin/blog">
            <v-list-item-content>
              <v-list-item-title>Ver Publicaciones</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link to="/admin/blog/categories">
            <v-list-item-content>
              <v-list-item-title>Categorías</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted-type</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item link to="/admin/blog/authors">
            <v-list-item-content>
              <v-list-item-title>Autores</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-face</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item link to="/admin/blog/tags">
            <v-list-item-content>
              <v-list-item-title>Etiquetas</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-tag</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group> -->

        <!--         <v-list-item link to="/admin/configuracion/redes-sociales">
          <v-list-item-action>
            <v-icon>mdi-finance</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Reportes</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-group prepend-icon="" no-action>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Configuración</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link to="/admin/settings/company">
            <v-list-item-content>
              <v-list-item-title>Empresa</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-office-building</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/settings/menu">
            <v-list-item-content>
              <v-list-item-title>Menu</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-menu</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/settings/sliderHome">
            <v-list-item-content>
              <v-list-item-title>Slider Home</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-image-multiple</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/settings/calltoaction">
            <v-list-item-content>
              <v-list-item-title>Call to Action</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-gesture-tap-button</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/settings/clientslogo">
            <v-list-item-content>
              <v-list-item-title>Clientes</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/settings/reviews">
            <v-list-item-content>
              <v-list-item-title>Reseñas</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-card-text-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/settings/colors">
            <v-list-item-content>
              <v-list-item-title>Colores</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-palette</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item link to="/admin/settings/fonts">
            <v-list-item-content>
              <v-list-item-title>Fuente</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-format-font</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>

        <v-list-item :href="website">
          <v-list-item-action>
            <v-icon>mdi-play</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>Ver Sitio Web</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="adminToolbar">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <!--       <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Buscar"
        class="hidden-sm-and-down"
      ></v-text-field> -->
      <v-spacer></v-spacer>

      <!--       <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn> -->

      <v-menu bottom min-width="200px" rounded offset-y v-if="user">
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar class="avatar-background" size="40">
              <span class="white--text headline" v-if="initials">{{
                initials
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar class="avatar-background">
                <span class="white--text headline" v-if="initials">{{
                  initials
                }}</span>
              </v-avatar>
              <h3 class="pt-4">
                {{ user.name + " " + user.lastname }}
              </h3>
              <p class="caption mt-1">{{ user.email }}</p>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text link to="/admin/editaccount">
                Editar cuenta
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text @click="logout"> SALIR </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="v-main">
      <router-view />
    </v-main>
    <v-footer app>
      <span
        >Powered by
        <a target="_blank" href="https://damianguilisasti.com.ar"
          >Damián Guilisasti</a
        >
        &copy; {{ new Date().getFullYear() }}</span
      >
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AdminView",
  data: () => ({
    drawer: null,
  }),
  methods: {
    async setCompanyURL() {
      try {
        const website =
          "https://" +
          window.location.hostname.split(".")[1] +
          "." +
          window.location.hostname.split(".")[2] +
          "." +
          window.location.hostname.split(".")[3];
        await this.$store.dispatch(
          "settings/setCompanyURL",
          {
            _id: this.dataId,
            companyURL: website,
          },
          {
            root: true,
          }
        );
      } catch (error) {
        console.log(error);
        this.$store.dispatch("snackbar/setSnackbar", {
          text: `No se pudo setear la URL de la empresa.`,
          color: "red",
        });
      }
    },
    async getUserData() {
      try {
        await this.$store.dispatch("user/getUser", null, { root: true });
        this.setCompanyURL();
      } catch (error) {
        this.$store.dispatch("snackbar/setSnackbar", {
          text: `No se pudo obtener la información del usuario.`,
          color: "red",
        });
      }
    },
    /*     isAdmin() {
      if (this.userInfo.rol[0].name === "Admin") return true;
      else return false;
    }, */
    logout() {
      this.$store.dispatch("user/exit");
    },
  },
  async created() {
    await this.$store.dispatch("user/autoLogin");
    this.getUserData();
  },
  computed: {
    ...mapGetters("settings", ["settings"]),
    ...mapGetters("user", ["user"]),
    initials() {
      return this.user.name.slice(0, 1) + this.user.lastname.slice(0, 1);
    },
    website() {
      return (
        "https://" +
        window.location.hostname.split(".")[1] +
        "." +
        window.location.hostname.split(".")[2] +
        "." +
        window.location.hostname.split(".")[3]
      );
    },
    settingsData() {
      return this.settings[0];
    },
    companyName() {
      return this.settings[0].companyName;
    },
    dataId() {
      return this.settings[0]._id;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.v-main {
  padding: 20px !important;
}
.avatar-background {
  background: $primarycolor;
}
.v-list-item--link:before {
  background-color: #0027ff;
}
.v-navigation-drawer--fixed {
  z-index: 5;
}
</style>
